<template>
  <div
    @click.prevent="showDetail"
    class="product"
  >
    <div class="item-content">
      <div class="product-info">
        <img
          :src="hasPhoto ? photoPrincipale : logo"
          alt="avatar"
        >
        <div class="user-meta-info">
          <p class="product-name">
            {{ name }}
          </p>
          <p class="product-category">
            {{ categorie }}
          </p>
        </div>
      </div>
      <div class="product-price">
        <p class="product-category-addr">
          <span>Prix: </span>{{ price }} Fcfa
        </p>
      </div>
      <div class="product-rating">
        <p class="product-rating-inner">
          <span>Note: </span>
          <a class="d-flex align-center">
            <!-- Ajoutez ici une représentation visuelle de la note, par exemple des étoiles -->
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex'
  const logo = require('../../assets/img/logo256.png')
  export default {
      props: {
          product: {type: Object, required: true}
      },
      data(){
          return {
              logo
          }
      },
      methods: {     
          showDetail(){
              this.$router.push({name: 'market-product', params: {uid: this.product.uid}})
          }
      },
      computed: {
          ...mapGetters({
              categories: 'market/categories'
          }),
          hasProduct() {
              return this.product !== null && this.product !== undefined;
          },
          name() {
              return this.hasProduct ? this.product.libelle : '-';
          },
          categorie() {
              if (!this.hasProduct) return null;
              let c = this.categories.find(item => item.uid === this.product.categorie);
              return c ? c.libelle : null;
          },
          price() {
              return this.hasProduct ? this.product.actualAmount : null;
          },
          hasPhoto() {
              if (this.hasProduct) {
                  let photos = JSON.parse(this.product.photos);
                  return photos.length > 0;
              }
              return false;
          },
          photoPrincipale() {
              if (this.hasProduct) {
                  let photos = JSON.parse(this.product.photos);
                  return photos[0].url;
              }
              return null;
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  $third: #b50404;
  $product-padding: 20px;
  .product {
      font-size: 14px;
      padding: $product-padding; 
      cursor: pointer;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      &:hover {
          transform: scale(1.02);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
      .item-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          .product-info {
              img {
                  max-width: 200px;
                  height: 200px;
                  margin-bottom: 20px;
                  border-radius: 10px;
              }
              .user-meta-info {
                  text-align: center;
                  border-top: solid 1px rgba(0,0,0,0.1);
                  padding: 15px 0;
                  .product-name {
                      padding: 10px;
                      height: 50px;
                      font-size: 14px;
                      font-weight: bold;
                  }
                  .product-category {
                      padding: 10px;
                      background-color: $third;
                      color: white;
                      border-radius: 5px;
                      margin-top: 10px;
                  }
              }
          }
          .product-price {
              font-size: 16px;
              margin-top: 10px;
              color: #333;
          }
          .product-rating {
              margin-top: 10px;
              .product-rating-inner {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  span {
                      margin-right: 5px;
                  }
                  a {
                      display: flex;
                      align-items: center;
                  }
              }
          }
      }
  }
  </style>
  